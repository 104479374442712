import React, { useEffect, useState } from 'react';
import { handleGetOpenTenders } from '../../utils/api'; // Import the API function
import './AllTender.css';
import Frame from './img/Frame.png';
import { Link } from 'react-router-dom';

const Allvendor = () => {
  const [tenders, setTenders] = useState([]); // State to store tenders
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchTenders = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await handleGetOpenTenders();
        console.log(response);
        if (response.success) {
          setTenders(response.data);
        } else {
          setError('Failed to load tenders.');
        }
      } catch (err) {
        setError('An error occurred while fetching tenders.');
      } finally {
        setLoading(false);
      }
    };

    fetchTenders();
  }, []);

  // Helper function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  return (
    <div className="d-flex justify-center">
      <div className="tender-container">
        <h1 className="tender-heading">All Tenders</h1>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : (
          <div className="tender-grid">
          {tenders.map((tender) => (
  <div key={tender._id} className="tender-card">
    <img src={Frame} alt="Tender Image" />
    <div className="tender-info">
      <h2>{tender.eventName}</h2>
      <h4 className='location'>Jaipur</h4>
      <p>{formatDate(tender.startDate)} - {formatDate(tender.endDate)}</p>
      <div className="d-flex tendor-button">
        <div className={`tender-status tender-${tender.status.toLowerCase()}`}>
          {tender.status}
        </div>
        <Link to={`/tender/${tender._id}`} className="tender-view-details">View Details</Link>
      </div>
    </div>
  </div>
))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Allvendor;
