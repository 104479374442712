import React from "react";
import styles from "./Hero.module.css";
import { Link } from "react-router-dom";
// import Lottie from 'lottie-react';
// import animation from "../hero/1715162176480.json";
// import SVG from "./SVG";

// import { Caveat } from 'next/font/google'
// const caveat = Caveat({ subsets: ['latin'] })

function Herosection() {
  // const scrollToDemoForm = () => {
  //   const demosection = document.getElementById("demo");
  //   if (demosection) {
  //     demosection.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  return (
    <>
    
    {/* <div className={caveat.className}> */}
    <div className={styles.hero_section} id="hero">
      <div className={styles.hero_comp}>
        <div className={styles.hero_content}>
          <div className={styles.hero_content_heading}>
            <h2 className={styles.roboto_regular_italic}><span className={styles.colorlogo}>Rental Pe</span> The Matchmaker of the Rental World   </h2>
            {/* <SVG></SVG> */}
          </div>
          {/* <p className={styles.hero_content_tagline}>
           Introducing Rental PE, a SaaS platform designed to manage party rental businesses efficiently while connecting them with event hosts through a streamlined bidding system.
          </p> */}
          <p className={`${styles.hero_content_tagline} ${styles.highlight}`}>
            Find Your <span className={`${styles.colorlogo} ${styles.boald}`}>Event Vendors</span>  frome here and get  <span className={`${styles.colorlogo} ${styles.boald}`}>Free</span> Event Insurance upto <span className={`${styles.colorlogo} ${styles.boald}`}>5 Cr.</span>
          </p>
          <span className={styles.hero_demo}>
            <div className={styles.hero_demo_text}>
            <Link to={'/vendorFinder'}><button className={styles.trybutton}  >Event Rentals</button></Link>  
              <span className={styles.hero_demo_line}></span>
            </div>
            <Link to={'/vendorFinder'}> <button className={styles.hero_cta}  >
            Try Rental Pe
            </button></Link> 
          </span>
          
        </div>
      </div>
    </div>
      <div className={`${styles.icic} m-3  align-center justify-ce`}>
      <img src="/serviceImages/ici.jpg" width={300} alt="" />
      <h3></h3>
      <div className={`${styles.hero_content_heading}`}>
            <h2 className={styles.roboto_regular_italic}><span className={styles.colorlogo}>You are Insured by ICICI Lombard </span>  </h2>
            {/* <SVG></SVG> */}
          </div>
      </div>

    {/* </div> */}
    </>
  );
}

export default Herosection;
