import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { resetPassword } from '../../utils/api';
import './ResetPassword.css'

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams(); // Token from URL

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await resetPassword(token, password);
      setMessage('Password reset successful');
      window.location.href = '/login'; // Redirect to login on success
    } catch (err) {
      setMessage('Error resetting password');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="New Password" />
      <button type="submit">Reset Password</button>
      {message && <p>{message}</p>}
    </form>
  );
};

export default ResetPassword;
