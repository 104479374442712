import React, { useEffect, useState } from 'react';
import './EventorgProfile.css';
import logo from './img/logo.png';
import '../AllVendors/AllTender.css';
import { Link } from 'react-router-dom';
import Frame from './img/Frame.png';
import { handleGetUserById, handleGetUserTender, handleUpdateUser } from '../../utils/api';

const VendorProfile = () => {
  const [user, setUser] = useState(null);
  const [tenders, setTenders] = useState([]);
  const [filteredTenders, setFilteredTenders] = useState([]);
  const [filter, setFilter] = useState('All'); // Current filter
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const [isSmallScreen, setIsSmallScreen] = useState(false); // Track screen size

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await handleGetUserById();
        if (response.success) {
          setUser(response.data.data);
          setUpdatedData(response.data.data);

          const tenderResponse = await handleGetUserTender();
          if (tenderResponse.success) {
            setTenders(tenderResponse.data.data);
            setFilteredTenders(tenderResponse.data.data); // Set initial tenders
          } else {
            setError('Failed to load user tenders.');
          }
        } else {
          setError('Failed to load user data.');
        }
      } catch (err) {
        setError('An error occurred while fetching user data.');
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  // Handle form field changes
  const handleChange = (e) => {
    setUpdatedData({ ...updatedData, [e.target.name]: e.target.value });
  };

  // Handle form submission for updating user data
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await handleUpdateUser(updatedData);
    if (response.success) {
      alert('User updated successfully!');
      setUser(updatedData);
      setEditMode(false);
    } else {
      alert('Failed to update user. Please try again.');
    }
  };

  // Handle tender filtering
  const handleFilterChange = (filterOption) => {
    setFilter(filterOption);
    if (filterOption === 'All') {
      setFilteredTenders(tenders);
    } else {
      setFilteredTenders(tenders.filter((tender) => tender.status === filterOption));
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  return (
    <div className="profile-container">
      {/* Profile Header */}
      <div className="profile-header">
        <img src={logo} alt="Profile" className="profile-image" />
      </div>

      {/* Profile Info Section */}
      <div className="profile-info">
        <div className="d-flex align-center event-gap">
          <h2>{user?.name || 'Amrapali Tent'}</h2>
          <p className="eventorg">( Event Organizer )</p>
          <Link to="#" onClick={() => setEditMode(!editMode)}>
            <i className="fas fa-edit"></i>
          </Link>
        </div>
        <p className="eventorgdown">( Event Organizer )</p>
        <p><i className="fas fa-map-marker-alt"></i> {user?.profile?.location || 'Delhi NCR'}</p>
        <div className="contact-info">
          <i className="fas fa-phone-alt"></i> {user?.phone || '+94133-33080'}
        </div>
      </div>
        {/* About Section */}
        <div className="profile-section">
        <h3>About {user?.name || 'Amrapali Tent'} – Tent, Delhi NCR</h3>
        <p>{user?.profile?.bio || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}</p>
      </div>

      {/* Terms and Conditions Section */}
      <div className="profile-section">
        <h3>Terms and Conditions</h3>
        <p>{user?.terms || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}</p>
      </div>

      {/* Edit Profile Form */}
      {editMode && (
        <form className="profile-edit-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={updatedData.name || ''}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={updatedData.email || ''}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Phone:</label>
            <input
              type="text"
              name="phone"
              value={updatedData.phone || ''}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <button type="submit" className="update-button">Update Profile</button>
        </form>
      )}

      {/* Tenders Section */}
      <div className="profile-section">
        {/* Filter UI */}
        {isSmallScreen ? (
          <select
            className="filter-dropdown"
            value={filter}
            onChange={(e) => handleFilterChange(e.target.value)}
          >
            <option value="All">All Tenders</option>
            <option value="Open">Open Tenders</option>
            <option value="Awarded">Awarded Tenders</option>
            <option value="Closed">Closed Tenders</option>
          </select>
        ) : (
          <div className="filter-tabs">
            {['All', 'Open', 'Awarded', 'Closed'].map((option) => (
              <button
                key={option}
                className={`filter-tab ${filter === option ? 'active' : ''}`}
                onClick={() => handleFilterChange(option)}
              >
                {option} Tenders
              </button>
            ))}
          </div>
        )}

        {/* Tenders Grid */}
        <div className="tender-grid">
          {filteredTenders.length > 0 ? (
            filteredTenders.map((tender) => (
              <div key={tender._id} className="tender-card">
                <img src={Frame} alt="Tender" />
                <div className="tender-info">
                  <h4>{tender.eventName}</h4>
                  <p>{formatDate(tender.startDate)} - {formatDate(tender.endDate)}</p>
                  <div className="tender-status-container">
                    <span className={`tender-status ${tender.status.toLowerCase()}`}>
                      {tender.status}
                    </span>
                    <Link to={`/tender/${tender._id}`} className="view-details-link">
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No tenders found for the selected filter.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VendorProfile;
