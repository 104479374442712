import React from 'react';
import './VendorProfile.css';
import logo from './img/logo.png';
import protfolio from './img/protfolio.png'
import { Link } from 'react-router-dom';

const VendorProfile = () => {
  return (
    <div className="profile-container">
      <div className="profile-header">
        <img src={logo} alt="Profile" className="profile-image" />
      </div>
      
      <div className="profile-info">
      <div className="d-flex align-center event-gap">

<h2>Amrapali Tent</h2>
<Link to="#"><i className="fas fa-edit"></i></Link>

</div>
        <p><i className="fas fa-map-marker-alt"></i> Delhi NCR</p>
        <div className="contact-info">
          <i className="fas fa-phone-alt"></i> +94133-33080
        </div>
        {/* <button className="edit-button">Edit</button> */}
      </div>

      <div className="profile-section">
        <h3>About Amrapali Tent – Tent, Delhi NCR</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>

      <div className="profile-section">
        <h3>Terms and Conditions</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>

      <div className="portfolio-container">
        <div className="portfolio-category">
          <h3>Portfolio (50)</h3>
          <div className="portfolio-grid">
            {/* Add image items */}
            <div className="portfolio-item">
              <img src={protfolio} alt="Portfolio Item" />
            </div>
            <div className="portfolio-item">
              <img src={protfolio} alt="Portfolio Item" />
            </div>
            {/* Repeat portfolio items as needed */}
          </div>
        </div>

        <div className="portfolio-category">
          <h3>Verified Portfolio (20)</h3>
          <div className="portfolio-grid">
            <div className="portfolio-item">
              <img src={protfolio} alt="Verified Portfolio Item" />
              <div className="portfolio-overlay">
                <p>Tent Of Amrapali Tent</p>
                <span>10</span>
              </div>
            </div>
            <div className="portfolio-item">
              <img src={protfolio} alt="Verified Portfolio Item" />
              <div className="portfolio-overlay">
                <p>Tent Of Amrapali Tent</p>
                <span>10</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorProfile;
