import React, { useState } from 'react';
import './Vendordetails.css';
// import Similarcard from './SimilarVendorCard/Similarcard';
import Reviews from './Reviews/Reviews';
import Comment from './Comment/Comment';
import VendorProfile from '../VendorProfile/VendorProfile';

const Vendorprofile = ({ vendorData }) => {
  const [activeTab, setActiveTab] = useState("portfolio");
  const [portfolioType, setPortfolioType] = useState("all");

  // Default sample data if `vendorData` is not provided
  const sampleVendorData = {
    name: "Sample_Vendor",
    location: "Sample_Location",
    rating: 4.0,
    reviewsCount: 100,
    portfolio: {
      all: [
        { id: 1, image: "https://via.placeholder.com/150", alt: "Portfolio Image 1" },
        { id: 2, image: "https://via.placeholder.com/150", alt: "Portfolio Image 2" },
      ],
      verified: [
        { id: 1, image: "https://via.placeholder.com/150", alt: "Verified Portfolio Image 1" },
      ],
    },
    startingPrice: "On Request",
    topImage: "https://via.placeholder.com/600x400",
  };

  const data = vendorData || sampleVendorData;

  // Replace underscores with spaces for display purposes
  const displayName = data.name.replace(/_/g, ' ');

  // Check for the existence of portfolio properties
  const allPortfolio = data.portfolio || [];
  const verifiedPortfolio = data.verifiedPortfolio || [];

  // Function to handle portfolio switching (all vs verified)
  const handlePortfolioSwitch = (type) => {
    setPortfolioType(type);
  };

  return (
    <div className="backgroundgray">
      <div className="vendor-container">
        {/* Left profile card - 70% width */}
        <div className="vendor-profile-card">
          <img
            src={data.image}
            alt="Vendor Profile"
            className="vendor-profile-image"
          />
          <div className="vendor-profile-details">
            <h2>{displayName}</h2>
            <p className="vendor-location">📍 {data.location.replace(/_/g, ' ')}</p>
            <p className="vendor-reviews">{data.rating} ⭐ | {data.reviewsCount} reviews</p>
            <div className="vendor-actions">
              <button className="vendor-action-button vendor-photos-button">
                <i className="fa fa-photo"></i> Photos
              </button>
              <button className="vendor-action-button vendor-shortlist-button">
                <i className="fa fa-heart"></i> Shortlist
              </button>
              <button className="vendor-action-button vendor-review-button">
                <i className="fa fa-pencil"></i> Write a review
              </button>
              <button className="vendor-action-button vendor-share-button">
                <i className="fa fa-share"></i> Share
              </button>
            </div>
          </div>

          <div className="vendor-tabs w-100">
            <button
              className={`vendor-tab w-50 ${activeTab === "portfolio" ? "vendor-active" : ""}`}
              onClick={() => setActiveTab("portfolio")}
            >
              Project
            </button>
            <button
              className={`vendor-tab w-50 ${activeTab === "about" ? "vendor-active" : ""}`}
              onClick={() => setActiveTab("about")}
            >
              About
            </button>
          </div>

          {activeTab === "portfolio" && (
            <>
              {/* Portfolio/Verified Portfolio Navigation */}
              <div className="vendor-portfolio-nav w-100">
                <button
                  className={`vendor-portfolio-btn w-50 ${portfolioType === "all" ? "vendor-active" : ""}`}
                  onClick={() => handlePortfolioSwitch("all")}
                >
                  Portfolio ({allPortfolio.length})
                </button>
                <button
                  className={`vendor-portfolio-btn w-50 ${portfolioType === "verified" ? "vendor-active" : ""}`}
                  onClick={() => handlePortfolioSwitch("verified")}
                >
                  Verified Portfolio ({verifiedPortfolio.length})
                </button>
              </div>

              {/* Portfolio Gallery */}
              <div className="vendor-portfolio-gallery">
                {(portfolioType === "all" ? allPortfolio : verifiedPortfolio).map((item,i) => (
                  <div className="vendor-image-container" key={i}>
                    <img src={item} alt={ "Portfolio Image"} />
                  </div>
                ))}
              </div>

              {/* View More Button */}
              <div className="vendor-view-more">
                <button>View more</button>
              </div>
            </>
          )}

          {activeTab === "about" && (
            <div className="vendor-about-section">
              <VendorProfile />
            </div>
          )}
        </div>

        {/* Right contact card - 30% width */}
        <div className="vendor-contact-card">
          <div className="vendor-price-info">
            <p>Starting Price</p>
            <hr className="hr" />
            <h3>{data.startingPrice}</h3>
          </div>
          <div className="vendor-contact-form">
            <div className="d-flex justify-center align-center navbarnone">
              <div className="vendor-button d-flex w-100">
                <button className="vendor-send-message w-50">Send message</button>
                <button className="login w-50">+91 93761 95533</button>
              </div>
            </div>
          </div>

          {/* <Similarcard /> */}
        </div>
      </div>

      {/* Reviews and Comments */}
      <div className="vendor-container1">
        <Reviews />
        <Comment />
      </div>
    </div>
  );
};

export default Vendorprofile;
