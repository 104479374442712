import React, { useState } from "react";
import "./Vendorcard.css";
import allCategoriesData from "../../data/sampleVendorDetails.json";
import { Link } from "react-router-dom";

const Vendorcard = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");

  // Extract categories from the JSON structure
  const categories = allCategoriesData.vendors.categories;

  const getVendorsToDisplay = () => {
    if (selectedCategory === "all") {
      // Flatten all types under all categories
      return categories.flatMap((category) => category.types);
    }
    // Find the selected category and return its types
    const selected = categories.find((category) => category.name === selectedCategory);
    return selected ? selected.types : [];
  };

  const getTagColor = (tag) => {
    switch (tag.toLowerCase()) {
      case "popular":
        return "red";
      case "premium":
        return "gold";
      case "verified":
        return "green";
      default:
        return "gray"; // Default color for unknown tags
    }
  };

  const displayedVendors = getVendorsToDisplay();

  return (
    <div>
      {/* Updated Filter Section with Icons */}
      <div className="categories-container">
        {/* Desktop Categories */}
        <div className="searching-categories">
          <div
            className={`searching-category ${selectedCategory === "all" ? "active" : ""}`}
            onClick={() => setSelectedCategory("all")}
          >
            <span>🏠All Vendors</span>
          </div>
          {categories.map((category) => (
            <div
              key={category.name}
              className={`searching-category ${selectedCategory === category.name ? "active" : ""}`}
              onClick={() => setSelectedCategory(category.name)}
            >
              <img src={category.icon || "/assets/images/default-icon.png"} alt={category.name} />
              <span>{category.name.replace(/_/g, " ")}</span>
            </div>
          ))}
        </div>

        {/* Mobile Dropdown Categories */}
        <div className="mobile-category-dropdown">
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="all">All Vendors</option>
            {categories.map((category) => (
              <option key={category.name} value={category.name}>
                {category.name.replace(/_/g, " ")}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Vendor Cards */}
      <div className="container">
        {displayedVendors.map((vendor, index) => (
          <div key={index} className="card-home">
            <div className="image-container">
              {vendor.tag && (
                <span
                  className="tag"
                  style={{ backgroundColor: getTagColor(vendor.tag) }}
                >
                  {vendor.tag}
                </span>
              )}
              <img
                src={vendor.image || "https://via.placeholder.com/150"}
                alt={`${vendor.name.replace(/_/g, " ")} Image`}
              />
            </div>
            <div className="shadow">
              <div className="info-overlay">
                <div className="rating">{vendor.rating}★</div>
              </div>
            </div>
            <div className="card-body">
              <div className="vendor-name">{vendor.name.replace(/_/g, " ")}</div>
              <p className="card-location">{vendor.location.replace(/_/g, " ")}</p>
              <div className="card-footer">
                <p className="starting-price">
                  From ₹{vendor.starting_price}
                </p>
                <Link to={`/vendorprofile/${vendor.name.replace(/\s/g, "_")}`}>
                  <button className="card-button">See Profile</button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Vendorcard;
