import React, { useState, useEffect } from "react";
import './Navbar.css';
import { FaBars, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [show, setShow] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [activeButton, setActiveButton] = useState(''); // Track active button
  const navigate = useNavigate();

  useEffect(() => {
    setLoggedIn(!!localStorage.getItem('token'));
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown')) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const scrollToPricing = () => {
    const aboutSection = document.getElementById("price");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToHero = () => {
    const aboutSection = document.getElementById("hero");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setLoggedIn(false);
    navigate('/login');
  };

  return (
    <div>
      <div className="width100 d-flex text-center justify-center justify-around">
        <div className="width331 d-flex justify-center align-center" onClick={scrollToHero}>
          <img className='logo1' src="./assets/images/logo1.png" alt="" />
        </div>
        <div className="menu width33 d-flex justify-center align-center navbarnone">
          <ul className='d-flex ul'>
            <li onClick={scrollToPricing} className='mx-3'>
              <Link className='menu' to={"/vendorFinder"}>Home</Link>
            </li>
            <li className='mx-3'>
              <div className="dropdown">
                <span className="menu dropdown-toggle" onClick={toggleDropdown}>
                  Tender {dropdownVisible ? <FaChevronUp /> : <FaChevronDown />}
                </span>
                {dropdownVisible && (
                  <div className="dropdown-menu">
                    <Link to="/createtender" className="dropdown-item">Create Tender</Link>
                    <Link to="/alltenders" className="dropdown-item">All Tenders</Link>
                  </div>
                )}
              </div>
            </li>
            {/* <li className='mx-3'><Link className='menu' to="">Services</Link></li> */}
          </ul>
        </div>
        <div className="width33 d-flex justify-center align-center navbarnone">
          {loggedIn ? (
            <div className=" d-flex">
              <button className='logout-button' onClick={handleLogout}>Logout</button>
              <Link to={'/eventorgprofile'}>
                <button className="profile-button">Profile</button>
              </Link>
            </div>
          ) : (
            <div className="button1 d-flex">
              <Link to={"/signup"}>
                <button
                  className={`signup-button ${activeButton === 'signup' ? 'active' : ''}`}
                  onClick={() => setActiveButton('signup')}
                >
                  SignUp
                </button>
              </Link>
              <Link to={"/login"}>
                <button
                  className={`login ${activeButton === 'login' ? 'active' : ''}`}
                  onClick={() => setActiveButton('login')}
                >
                  Login
                </button>
              </Link>
            </div>
          )}
        </div>
        <div className="justify-center align-center d-flex">
          <FaBars className="bars" onClick={() => setShow(!show)} />
        </div>
        <div className={`smallnav ${show ? 'navvisible' : 'navhide'}`}>
          <div className="menu width33 d-flex justify-start align-center">
            <ul className='ul margin1rembottom'>
              <li onClick={scrollToPricing} className='mx-3'>
                <Link className='menu' to="/vendorFinder">Home</Link>
              </li>
              <li className='mx-3'>
                <div className="dropdown">
                  <span className="menu dropdown-toggle" onClick={toggleDropdown}>
                    Tender {dropdownVisible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                  {dropdownVisible && (
                    <div className="dropdown-menu">
                      <Link to="/createtender" className="dropdown-item">Create Tender</Link>
                      <Link to="/alltenders" className="dropdown-item">All Tenders</Link>
                    </div>
                  )}
                </div>
              </li>
              {/* <li className='mx-3'><Link className='menu' to="">Pricing</Link></li> */}
              {/* <li className='mx-3'><Link className='menu' to="">Services</Link></li> */}
            </ul>
          </div>
          <div className="width33 d-flex justify-start align-center">
            {loggedIn ? (
              <div className="d-flex">
                <button className='logout-button' onClick={handleLogout}>Logout</button>
                <Link to={'/eventorgprofile'}>
                  <button className="profile-button">Profile</button>
                </Link>
              </div>
            ) : (
              <div className="button d-flex">
                <Link to={'/signup'}>
                  <button
                    className={`signup-button ${activeButton === 'signup' ? 'active' : ''}`}
                    onClick={() => setActiveButton('signup')}
                  >
                    SignUp
                  </button>
                </Link>
                <Link to={'/login'}>
                  <button
                    className={`login ${activeButton === 'login' ? 'active' : ''}`}
                    onClick={() => setActiveButton('login')}
                  >
                    Login
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
