import React, { useState } from 'react';
import { handleSignUp } from '../../utils/api';
import './signup.css';
import { Link } from 'react-router-dom';
import { IoPeopleOutline } from "react-icons/io5";
import { CiMail, CiLock } from "react-icons/ci";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'; // Import Eye icons
import Spinner from '../../components/spinner/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUp = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility

  const handleSignUpClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {
      setError('Please enter a valid email address');
      toast.error('Please enter a valid email address');
      setLoading(false);
      return;
    }

    try {
      const { success, error } = await handleSignUp({ name, email, password });
      if (success) {
        toast.success('Sign-up successful! Redirecting to login...');
        setTimeout(() => {
          window.location.href = '/login';
        }, 3000);
      } else {
        setError(error);
        toast.error(error);
      }
    } catch (err) {
      setError('Error during sign-up. Please try again.');
      toast.error('Error during sign-up. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  return (
    <div className="">
      <div className="signup">
        <div className="leftsignup">
          <img src="./assets/images/signup.png" alt="Sign-up illustration" />
        </div>
        <div className="rightSignup">
          <div className="signUpTitle">SignUp</div>
          <form onSubmit={handleSignUpClick}>
            <div className="inputdiv">
              <IoPeopleOutline className='inputicon' />
              <input 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                type="text" 
                placeholder="Name" 
                required 
              />
            </div>
            <div className="inputdiv">
              <CiMail className='inputicon' />
              <input 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                type="email" 
                placeholder="Email" 
                required
                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                title="Please enter a valid email address."
              />
            </div>
            <div className="inputdiv">
              <CiLock className='inputicon' />
              <input 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                type={passwordVisible ? 'text' : 'password'} // Toggle input type
                placeholder="Password" 
                required 
              />
              {passwordVisible ? (
                <AiOutlineEyeInvisible
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <AiOutlineEye
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                />
              )}
            </div>
            <div className="bootomsign">
              <button className='submitButtonForSignup' type="submit" disabled={loading}>
                {loading ? 'Signing Up...' : 'SignUp'}
              </button>
              <div className="bootomlineSignup">
                Already have an account? <Link className='loginbutton' to={'/login'}><span>Login</span></Link>
              </div>
            </div>
            {loading && <Spinner />}
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SignUp;
