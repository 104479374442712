import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { handleGetTenderById, handleGetBidsForTender, handleSubmitFeedback } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import Rating from 'react-rating-stars-component'; // Star rating library
import './Tenderdetails.css';
import Profile from './img/Rectangle.png';
import Spinner from '../spinner/Spinner';

Modal.setAppElement('#root'); // Accessibility for screen readers

const TenderDetails = () => {
  const { id } = useParams(); // Tender ID from the route
  const [tender, setTender] = useState(null);
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [negotiationPrice, setNegotiationPrice] = useState('');
  const [selectedBidId, setSelectedBidId] = useState(null);

  useEffect(() => {
    const fetchTenderDetails = async () => {
      setLoading(true);
      try {
        const response = await handleGetTenderById(id);
        if (response.success) {
          setTender(response.data.data);
          setSelectedVendorId(response.data.data.selectedVendor?._id); // Set the selected vendor ID
        } else {
          setError('Failed to load tender details.');
        }
      } catch (err) {
        setError('An error occurred while fetching the tender.');
        console.error('Error fetching tender:', err);
      } finally {
        setLoading(false);
      }
    };

    const fetchBidsForTender = async () => {
      try {
        const response = await handleGetBidsForTender(id);
        if (response.success) {
          const sortedBids = Array.isArray(response.data.data)
            ? response.data.data.sort((a, b) => a.price - b.price)
            : [];
          setBids(sortedBids);
        } else {
          toast.error(response.msg || 'Failed to load bids.');
        }
      } catch (err) {
        toast.error('An error occurred while fetching bids.');
        console.error('Error fetching bids:', err);
      }
    };

    fetchTenderDetails();
    fetchBidsForTender();
  }, [id]);

  const handleCloseTender = async () => {
    if (!rating || rating < 1 || rating > 5) {
      toast.error('Please provide a valid rating between 1 and 5.');
      return;
    }

    if (!tender || !selectedVendorId) {
      toast.error('Tender or Vendor information is missing.');
      return;
    }

    const feedbackData = {
      tenderId: tender._id, // Tender ID
      vendorId: selectedVendorId, // Vendor ID
      rating, // User-provided rating
      feedback, // User-provided feedback
    };

    try {
      const response = await handleSubmitFeedback(tender._id, feedbackData);
      if (response.success) {
        toast.success('Feedback submitted successfully!');
        setShowRatingModal(false);
      } else {
        toast.error(response.msg || 'Failed to submit feedback.');
      }
    } catch (error) {
      toast.error('An error occurred while submitting feedback.');
      console.error("Error while submitting feedback:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  if (loading) return <Spinner />;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="bid-container">
      {tender && (
        <div className="bid-details-section">
          <h2>{tender.eventName}</h2>
          <div className="bid-detail-item">
            <strong>Service Required:</strong> <span>{tender.servicesRequired.join(', ')}</span>
          </div>
          <div className="bid-detail-item">
            <strong>City:</strong> <span>{tender.location}</span>
          </div>
          <div className="bid-detail-item">
            <strong>Budget:</strong> <span>₹{tender.budget}</span>
          </div>
          <div className="bid-detail-item">
            <strong>Status:</strong> <span>{tender.status}</span>
          </div>
          <div className="bid-detail-item">
            <strong>Expire Date:</strong> <span>{formatDate(tender.expirationDate)}</span>
          </div>
          <div className="bid-detail-item">
            <strong>Start Date:</strong> <span>{formatDate(tender.startDate)}</span>
          </div>
          <div className="bid-detail-item">
            <strong>End Date:</strong> <span>{formatDate(tender.endDate)}</span>
          </div>
          <div className="bid-description">
            <strong>Description:</strong> <p>{tender.description}</p>
          </div>
          {tender.status === 'Awarded' && (
            <button
              className="close-tender-button"
              onClick={() => setShowRatingModal(true)}
            >
              Close Tender
            </button>
          )}
        </div>
      )}

      {bids && bids.length > 0 ? (
        <div className="bid-bidding-section">
          <h3>Bids</h3>
          {bids.map((bid) => (
            <div key={bid._id} className="bid-item-container">
              <div className="bid-item">
                <div className="bid-user-info">
                  <img src={Profile} alt="Vendor Avatar" className="bid-user-avatar" />
                  <span className="bid-name">{bid.vendor.name}</span>
                </div>
                <div className="bid-details">
                  <p className="bid-amount">
                    <strong>Bid:</strong> ₹{bid.price}
                  </p>
                  <p className="bid-date">
                    <strong>Applied On:</strong> {formatDate(bid.createdAt)}
                  </p>
                  {bid.negotiationPrice && (
                    <p className="negotiation-price">
                      <strong>Negotiation Price:</strong> ₹{bid.negotiationPrice}
                    </p>
                  )}
                </div>
              </div>
              <div className="bid-actions">
                <button
                  className="bid-button negotiate-button"
                  onClick={() => {
                    setSelectedBidId(bid._id);
                    setNegotiationPrice('');
                  }}
                >
                  Negotiate
                </button>
                <button
                  className="bid-button final-vendor-button"
                  disabled={tender?.status === 'Awarded'}
                >
                  Final Vendor
                </button>
              </div>

              {selectedBidId === bid._id && (
                <div className="negotiation-input-container">
                  <h4>Enter Negotiation Price</h4>
                  <div className="negotiation-input-group">
                    <input
                      type="text"
                      placeholder="Enter Negotiation Price"
                      value={negotiationPrice}
                      onChange={(e) => setNegotiationPrice(e.target.value)}
                      className="negotiation-input"
                    />
                    <button className="submit-negotiation-button">Submit</button>
                    <button
                      onClick={() => setSelectedBidId(null)}
                      className="cancel-negotiation-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>No bids have been submitted yet.</p>
      )}

      <Modal
        isOpen={showRatingModal}
        onRequestClose={() => setShowRatingModal(false)}
        className="rating-modal"
        overlayClassName="rating-modal-overlay"
      >
        <h3>Rate Vendor</h3>
        <div className="rating-input-group">
          <label>Rating:</label>
          <Rating
            count={5}
            size={30}
            activeColor="#ffd700"
            value={rating}
            onChange={(newRating) => setRating(newRating)}
          />
        </div>
        <div className="feedback-input-group">
          <label>Feedback:</label>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Write your feedback here"
          />
        </div>
        <div className="rating-modal-actions">
          <button
            onClick={handleCloseTender}
            className="submit-feedback-button"
            disabled={!tender || !selectedVendorId || rating < 1 || rating > 5}
          >
            Submit
          </button>
          <button
            onClick={() => setShowRatingModal(false)}
            className="cancel-feedback-button"
          >
            Cancel
          </button>
        </div>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default TenderDetails;
