import React from 'react';
import './Reviews.css';

const Reviews = () => {
  return (
    <div className='d-flex justify-center align-center'>
      <div className="review-container">
        
        {/* Rating Summary Section */}
        <div className="rating-summary">
          <div className="average-rating">
            <h1>4.5</h1>
            <div className="stars">
              <span>&#9733;</span>
              <span>&#9733;</span>
              <span>&#9733;</span>
              <span>&#9733;</span>
              <span>&#9733;</span>
            </div>
            <p>2,256,896 reviews</p>
          </div>
          <div className="rating-breakdown">
            <RatingBar star="5" percent="70%" reviewCount="2.5k reviews" />
            <RatingBar star="4" percent="40%" reviewCount="800 reviews" />
            <RatingBar star="3" percent="50%" reviewCount="1.2k reviews" />
            <RatingBar star="2" percent="20%" reviewCount="300 reviews" />
            <RatingBar star="1" percent="25%" reviewCount="387 reviews" />
          </div>
        </div>

        {/* Submit Review Section */}
        <div className="submit-review">
          <h2>Rating</h2>
          <div className="stars-input">
            <span>&#9734;</span>
            <span>&#9734;</span>
            <span>&#9734;</span>
            <span>&#9734;</span>
            <span>&#9734;</span>
          </div>

          <div className="submit-review-content">
            {/* Left side (70%): Name and review title */}
            <div className="review-form">
              <form>
                <input type="text" placeholder="Enter your name" />
                <input type="text" placeholder="Give your review a title" />
              </form>
            </div>

            {/* Right side (30%): Upload photo */}
            <div className="review-photo-upload">
              <div className="upload-photo">
                <input type="file" id="photo-upload" hidden />
                <label htmlFor="photo-upload" className="upload-button">&#8682; Upload photo</label>
              </div>
            </div>
          </div>
          <div className="review-form">
              <form>
                
                <textarea placeholder="Write your comment here"></textarea>
              </form>
            </div>

          <button className="reviews-button" type="submit">Submit review</button>
        </div>
      </div>
    </div>
  )
}

function RatingBar({ star, percent, reviewCount }) {
    return (
      <div className="rating-bar">
        <span>{star}</span>
        <div className="bar">
          <div className="fill" style={{ width: percent }}></div>
        </div>
        <span>{reviewCount}</span>
      </div>
    );
}

export default Reviews;
