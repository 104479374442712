import React from 'react'
import { Link } from 'react-router-dom'
import './Topnavbar.css'

const Topnavbar = () => {
    return (
        <div>
            <section className="top-menu">
                <div className="top-menu-left width42 d-flex justify-center">
                    <ul>
                        <li>
                            <Link href="#"><i className="fa-brands fa-facebook-f" ></i></Link>
                        </li>
                        <li>
                            <Link href="#"><i className="fa-brands fa-linkedin-in" ></i></Link>
                        </li>
                        <li>
                            <Link href="#"><i className="fa-brands fa-instagram" ></i></Link>
                        </li>
                        <li>
                            <Link href="#"><i className="fa-brands fa-twitter" ></i></Link>
                        </li>
                    </ul>
                </div>
<div className="width33"></div>

                <div className="top-menu-right width33">
                    <ul>
                        <li className='marginx'>
                            <Link href="#"><i class=" fix fa-solid fa-envelope "></i>  info@rentalpe.co.in</Link>
                        </li>
                        <li>
                            <Link href="#" className=" phone-icon"><i class=" fix fa-solid fa-phone"></i>+ 93761-95533</Link>
                        </li>
                    </ul>
                </div>



            </section>
        </div>
    )
}

export default Topnavbar
