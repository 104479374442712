// components/ForgetPassword.js
import React, { useState } from 'react';
import { forgetPassword } from '../../utils/api';
import './ForgetPassword.css' 
const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await forgetPassword(email);
      setMessage('Password reset email sent');
      setError(''); // Clear any previous error messages
    } catch (err) {
      // Log the error for debugging
      console.error('Error sending password reset email:', err);
      setMessage(''); // Clear any previous success messages
      setError('Error sending password reset email');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        type="email" 
        placeholder="Enter your email" 
        required 
      />
      <button type="submit">Reset Password</button>
      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </form>
  );
};

export default ForgetPassword;
